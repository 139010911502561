<template>
  <v-menu bottom left>
    <template v-slot:activator="{ on, attrs }">
      <v-avatar
        color="indigo"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon dark>
          mdi-account-circle
        </v-icon>
      </v-avatar>
    </template>
    <v-list>
      <v-list-item link>
        <v-list-item-action>
          <v-btn to="/account" class="blue darken-3 white--text">My Account</v-btn>
        </v-list-item-action>
      </v-list-item>
      <v-list-item link @click="logOut">
        <v-list-item-action>
          Log Out
        </v-list-item-action>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
  export default {
    name: 'AvatarMenu',

    methods: {
      logOut () {
        this.$store.dispatch('logOut')
      }
    }
  }
</script>

import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/storage'
import router from '@/router/index'
import { db } from '@/db'

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    isAuth: false,
    isVerified: false,
    myStory: '',
    userData: {},
    userUID: ''
  },
  mutations: {
    SET_IS_AUTH: (state, payload) =>          { state.isAuth = payload },
    SET_IS_VERIFIED: (state, payload) =>      { state.isVerified = payload },
    SET_MY_STORY: (state, payload) =>         { state.myStory = payload },
    SET_PHOTO_ALBUM: (state, payload) =>      { state.photoAlbum = payload },
    SET_USERDATA: (state, payload) =>         { state.userData = payload },
    SET_USER_UID: (state, payload) =>         { state.userUID = payload }
  },
  actions: {
    addStory ({ dispatch }, { storyData, mainImageFile }) {
      return new Promise((resolve, reject) => {
        db
        .collection('stories')
        .add(storyData)
        .then((resp) => {
          // Upload story's main image
          const response = resp
          if (mainImageFile) {
            dispatch('uploadFile', ({ file: mainImageFile, path: 'storyImages/' + response.id + '/mainPhoto/' + mainImageFile.name }))
            .then((mainImageURL) => {
              // Update story data with the image's URL
              dispatch('updateStory', { storyID: response.id, storyData: { mainImageURL: mainImageURL } })
              .then(() => {
                router.push({ path: 'story-view', query: { storyID: response.id } })
                resolve()
              })
              .catch((error) => {
                reject(error)
              })
            })
            .catch((error) => {
              reject(error)
            })
          } else {
            router.push({ path: 'story-view', query: { storyID: response.id } })
            resolve()
          }
        })
        .catch((error) => {
          reject(error)
        })
      })
    }, 

    confirmPasswordReset({ commit, dispatch }, { code, password }) {
      return new Promise((resolve) => {
        firebase
        .auth()
        .confirmPasswordReset(code, password)
        .then((resp) => {
          // Update user data with password reset flag
          commit('SET_USERDATA', { ...this.state.userData, isPasswordReset: true })
          dispatch('updateUserData', { userUID: this.state.userData.userUID })
          .then(() => {
            resolve(resp)
          })
          .catch((error) => {
            resolve(error)
          })
        })
        .catch((error) => {
          resolve(error)
        })
      })
    },

    // eslint-disable-next-line no-unused-vars
    deleteFile: ({ commit }, { path }) => {
      return new Promise((resolve, reject) => {
        firebase
        .storage()
        .ref()
        .child(path)
        .delete()
        .then(() => {
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
      })
    },

    // eslint-disable-next-line no-unused-vars
    deleteStory: ({ commit }, { storyID }) => {
      return new Promise((resolve, reject) => {
        db
        .collection('stories')
        .doc(storyID)
        .delete()
        .then(() => {
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
      })
    },

    // eslint-disable-next-line no-unused-vars
    getManagedStories: ({ commit }, { userUID }) => {
      return new Promise((resolve, reject) => {
        db
        .collection('stories')
        .where('manager', '==', userUID)
        .get()
        .then((querySnapshot) => {
          let stories = querySnapshot.docs.map(doc => {
            let data = doc.data()
            let id = doc.id
            return { id, ...data }
          })

          resolve(stories)
        })
        .catch((error) => {
          reject(error)
        })
      })
    },

    getMyStory: ({ commit }, { myStoryID }) => {
      return new Promise((resolve, reject) => {
        db
        .collection('stories')
        .doc(myStoryID)
        .get()
        .then((resp) => {
          commit('SET_MY_STORY', resp.data())

          resolve()
        })
        .catch((error) => {
          reject(error)
        })
      })
    },

    // eslint-disable-next-line no-unused-vars
    getStoryData: ({ commit }, { storyID }) => {
      return new Promise((resolve, reject) => {
        db
        .collection('stories')
        .doc(storyID)
        .get()
        .then((resp) => {
          resolve(resp.data())
        })
        .catch((error) => {
          reject(error)
        })
      })
    },

    getUserData: ({ commit }, { userUID }) => {
      return new Promise((resolve, reject) => {
        db
        .collection('users')
        .doc(userUID)
        .get()
        .then((resp) => {
          if (resp) { commit('SET_USERDATA', resp.data()) }

          resolve(resp)
        })
        .catch((error) => {
          reject(error)
        })
      })
    },

    logIn ({ commit, dispatch }, { email, password }) {
      return new Promise((resolve, reject) => {
        firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then((resp) => {
          commit('SET_IS_AUTH', true)
          commit('SET_USER_UID', resp.user.uid)
          commit('SET_IS_VERIFIED', resp.user.emailVerified)
          
          // Fetch the user's account data and put in local app state
          dispatch('getUserData', { userUID: resp.user.uid })
          .then(() => {
            // If the user has their own story, go fetch that story
            //if (resp.myStory) {
            //  this.$store.dispatch('stories/getMyStory', { myStoryID: resp.myStory })
            //}
            /* Go fetch any stories the user manages. The action takes two arguements, but we
            are only sending one. */
            //this.$store.dispatch('stories/getManagedStories', { userUID: refUserUID })
            router.push('/account')

            resolve()
          })
          .catch((error) => {
            reject(error)
          })
        })
        .catch((error) => {
          reject(error)
        })
      })
    },

    logOut ({ commit }) {
      return new Promise((resolve, reject) => {
        if (this.state.isAuth) {
          firebase
          .auth()
          .signOut()
          .then(() => {
            commit('SET_IS_AUTH', false)
            commit('SET_USER_UID', null)
            commit('SET_IS_VERIFIED', false)

            router.push('/login').catch(err => {
              // Ignore the vuex err regarding navigating to the same page
              if (err.name !== 'NavigationDuplicated' &&
                !err.message.includes('Avoided redundant navigation to current location')
              ) {
                // But print any other errors to the console
                console.log(err);
              }
            })

            resolve()
          })
          .catch((error) => {
            reject(error)
          })
        }
      })
    },

    // eslint-disable-next-line no-unused-vars
    register ({ commit }, { firstName, lastName, email, password1, password2, isVeteran }) {
      return new Promise((resolve, reject) => {
        firebase
        .auth()
        .createUserWithEmailAndPassword(email, password1)
        .then((resp) => {
          const response = resp
          // Send account verification email
          var actionCodeSettings = {
            url: window.location.origin + '/#/login'
          }
          firebase
          .auth()
          .currentUser
          .sendEmailVerification(actionCodeSettings)
          .then(() => {
            // Add new account to the 'users' collection in Firestore
            db
            .collection('users')
            .doc(response.user.uid)
            .set({
              firstName: firstName,
              lastName: lastName,
              emailAddress: email,
              created: new Date().getTime(),
              isVeteran: isVeteran
            })
            .then(() => {
              resolve()
            })
            .catch((error) => {
              reject(error)
            })
          })
          .catch((error) => {
            reject(error)
          })
        })
        .catch((error) => {
          reject(error)
        })
      })
    },

    // eslint-disable-next-line no-unused-vars
    resetPassword ({ commit }, { email }) {
      return new Promise((resolve, reject) => {
        firebase
        .auth()
        .sendPasswordResetEmail(email)
        .then(() => {
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
      })
    },

    // eslint-disable-next-line no-unused-vars
    resetPasswordConfirm ({ commit }, { code, newPassword }) {
      return new Promise((resolve, reject) => {
        firebase
        .auth()
        .confirmPasswordReset(code, newPassword)
        .then(() => {
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
      })
    },

    // eslint-disable-next-line no-unused-vars
    searchStories ({ commit }, { params }) {
      return new Promise((resolve, reject) => {
        db
        .collection('stories')
        .get()
        .then((querySnapshot) => {
          // Loop through each result to apply filters, until we find a better way to do this on back-end
          var stories = []
          querySnapshot.forEach((doc) => {
            var story = Object.assign({}, doc.data())
            story.id = doc.id

            // Apply filters
            var isFilteredOut = false

            // Basic Information
            if ((params.firstName && (!story.firstName || !story.firstName.toLowerCase().includes(params.firstName.toLowerCase()))) || 
            (params.middleName && (!story.middleName || !story.middleName.toLowerCase().includes(params.middleName.toLowerCase()))) ||
            (params.lastName && (!story.lastName || !story.lastName.toLowerCase().includes(params.lastName.toLowerCase()))) ||
            (params.maidenName && (!story.maidenName || !story.maidenName.toLowerCase().includes(params.maidenName.toLowerCase()))) ||
            (params.nickname && (!story.nickname || !story.nickname.toLowerCase().includes(params.nickname.toLowerCase()))) ||
            (params.dob && (!story.dob || story.dob !== params.dob)) ||
            (params.dod && (!story.dod || story.dod !== params.dod)) ||
            (params.gender && (!story.gender || story.gender !== params.gender)) ||
            (params.race && (!story.race || story.race !== params.race)) ||
            (params.species && (!story.species || story.species !== params.species)) ||
            (params.breed && (!story.breed || story.breed !== params.breed)) ||
            (params.homeTown && (!story.homeTown || story.homeTown !== params.homeTown))) {
              isFilteredOut = true
            }

            // Service Terms
            if (params.branch) {
              if (!story.serviceTerms || story.serviceTerms.length === 0) {
                isFilteredOut = true
              } else {
                var isBranchMatch
                for (var i = 0; i < story.serviceTerms.length; i++) {
                  if (story.serviceTerms[i].branch && params.branch.text === story.serviceTerms[i].branch.text) {
                    isBranchMatch = true
                    break
                  }
                }

                if (!isBranchMatch) isFilteredOut = true
              }
            }

            if (params.rank) {
              if (!story.serviceTerms || story.serviceTerms.length === 0) {
                isFilteredOut = true
              } else {
                var isRankMatch
                for (i = 0; i < story.serviceTerms.length; i++) {
                  if (story.serviceTerms[i].rank && params.rank.text === story.serviceTerms[i].rank.text) {
                    isRankMatch = true
                    break
                  }
                }

                if (!isRankMatch) isFilteredOut = true
              }
            }

            if (params.servedFrom) {
              if (!story.serviceTerms || story.serviceTerms.length === 0) {
                isFilteredOut = true
              } else {
                var isServedFromMatch
                for (i = 0; i < story.serviceTerms.length; i++) {
                  if (params.servedFrom === story.serviceTerms[i].servedFrom) {
                    isServedFromMatch = true
                    break
                  }
                }

                if (!isServedFromMatch) isFilteredOut = true
              }
            }

            if (params.servedTo) {
              if (!story.serviceTerms || story.serviceTerms.length === 0) {
                isFilteredOut = true
              } else {
                var isServedToMatch
                for (i = 0; i < story.serviceTerms.length; i++) {
                  if (params.servedTo === story.serviceTerms[i].servedTo) {
                    isServedToMatch = true
                    break
                  }
                }

                if (!isServedToMatch) isFilteredOut = true
              }
            }

            if (params.bootCamp) {
              if (!story.serviceTerms || story.serviceTerms.length === 0) {
                isFilteredOut = true
              } else {
                var isBootCampMatch
                for (i = 0; i < story.serviceTerms.length; i++) {
                  if (story.serviceTerms[i].bootCamp && story.serviceTerms[i].bootCamp.toLowerCase().includes(params.bootCamp.toLowerCase())) {
                    isBootCampMatch = true
                    break
                  }
                }

                if (!isBootCampMatch) isFilteredOut = true
              }
            }

            if (params.deployments) {
              if (!story.serviceTerms || story.serviceTerms.length === 0) {
                isFilteredOut = true
              } else {
                var isDeploymentsMatch
                for (i = 0; i < story.serviceTerms.length; i++) {
                  if (story.serviceTerms[i].deployments && story.serviceTerms[i].deployments.toLowerCase().includes(params.deployments.toLowerCase())) {
                    isDeploymentsMatch = true
                    break
                  }
                }

                if (!isDeploymentsMatch) isFilteredOut = true
              }
            }

            if (!isFilteredOut) stories.push(story)
          })
          
          resolve(stories)
        })
        .catch((error) => {
          reject(error)
        })
      })
    },

    // eslint-disable-next-line no-unused-vars
    setChapters ({ dispatch }, { storyID, chapters }) {
      console.log('here: ' + storyID + ', ' + JSON.stringify(chapters))
      return new Promise((resolve, reject) => {
        db
        .collection('stories')
        .doc(storyID)
        .collection('chapters')
        .doc('chapters')
        .set({
          chapters: chapters
        })
        .then((resp) => {
          resolve(resp)
          // Send chapter email
          /* const storyID = this.storyID
          const storyManager = this.storyData.manager
          const firstName = this.storyData.firstName
          const lastName = this.storyData.lastName
          axios({
            url: process.env.VUE_APP_ADDED_CHAPTER_URL,
            data: {
              storyID,
              storyManager: storyManager,
              firstName: firstName,
              lastName: lastName
            },
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            method: 'POST'
          })
          .then((resp) => {
            if (resp.data === 'success') {
              //
            } else {
              //
            }
          })
          .catch((error) => {
          }) */
        })
        .catch((error) => {
          reject(error)
        })
      })
    },

    // eslint-disable-next-line no-unused-vars
    updateStory ({ commit }, { storyID, storyData, mainImageFile }) {
      return new Promise((resolve, reject) => {
        db
        .collection('stories')
        .doc(storyID)
        .update(storyData)
        .then(() => {
          /* if (this.userData.myStory === this.storyID) {
            this.$store.dispatch('stories/getMyStory', { myStoryID: this.storyID })
          } else {
            this.$store.dispatch('stories/getManagedStories', { userUID: this.userUID })
          } */

          resolve()
        })
        .catch((error) => {
          reject(error)
        })
      })
    },

    // eslint-disable-next-line no-unused-vars
    updateUserData({ commit }, { userUID }) {
      return new Promise((resolve, reject) => {
        db
        .collection('users')
        .doc(userUID)
        .update(this.state.userData)
        .then(() => {
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
      })
    },

    // eslint-disable-next-line no-unused-vars
    uploadFile ({ commit }, { file, path }) {
      return new Promise((resolve, reject) => {
        firebase
        .storage()
        .ref()
        .child(path)
        .put(file, { "contentType": file.type })
        .then((snapshot) => {
          snapshot.ref
          .getDownloadURL()
          .then((url) => {
            resolve(url)
          })
          .catch((error) => {
            reject(error)
          })
        })
        .catch((error) => {
          reject(error)
        })
      })
    },

    uploadUserAvatar ({ dispatch }, { file }) {
      return new Promise((resolve, reject) => {
        firebase
        .storage()
        .ref()
        .child('accountImages/' + this.state.userUID + '/avatar/' + file.name)
        .put(file, { "contentType": file.type })
        .then((snapshot) => {
          snapshot.ref
          .getDownloadURL()
          .then((url) => {
            console.log('got it here: ' + url)
            // Update user's userData with avatar field pointing to photo url
            db
            .collection('users')
            .doc(this.state.userUID)
            .update({
              avatar: url
            })
            .then(() => {
              // Go fetch userData to get new avatar field
              dispatch('getUserData', { userUID: this.state.userUID })
              .then(() => {
                resolve()
              })
              .catch((error) => {
                reject(error)
              })
            })
            .catch((error) => {
              reject(error)
            })
          })
          .catch((error) => {
            reject(error)
          })
        })
        .catch((error) => {
          reject(error)
        })
      })
    },

    // eslint-disable-next-line no-unused-vars
    uploadStoryMainPhoto ({ commit }, { storyID, file }) {
      return new Promise((resolve, reject) => {
        // Upload photo to Google Firestore Storage and get url of photo
        const uploadPhoto = 
          firebase
          .storage()
          .ref()
          .child('storyImages/' + storyID + '/mainPhoto/' + file.name)
          .put(file, { "contentType": file.type })
          .then(() => {
            uploadPhoto.ref
            .getDownloadURL()
            .then((url) => {
              // Update the story's data with photo url
              db
              .collection('users')
              .doc(this.state.userUID)
              .update({
                avatar: url
              })
              .then((resp) => {
                // Go fetch userData to get new avatar field
                //this.$store.dispatch('users/getUserData', { userUID: this.userUID })
                //this.avatar = null
                //this.saving = false
                //this.saved = true
                resolve(resp)
              })
              .catch((error) => {
                reject(error)
              })
            })
            .catch((error) => {
              reject(error)
            })
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    // eslint-disable-next-line no-unused-vars
    verifyPasswordResetCode({ commit }, { code }) {
      return new Promise((resolve) => {
        firebase
        .auth()
        .verifyPasswordResetCode(code)
        .then((resp) => {
          resolve(resp)
        })
        .catch((error) => {
          resolve(error)
        })
      })
    }
  },
  modules: {
  },
  getters: {
    isAuth: state => state.isAuth,
    isVerified: state => state.isVerified,
    myStory: state => state.myStory,
    userData: state => state.userData,
    userUID: state => state.userUID
  }
})

import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Home.vue')
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('@/views/About.vue')
  },
  {
    path: '/account',
    name: 'Account',
    component: () => import('@/views/Account.vue'),
    beforeEnter: (to, from, next) => {
        if (!store.getters.isAuth) next({ name: 'LogIn' })
        else next()
    }
  },
  {
      path: '/auth-management',
      name: 'AuthManagement',
      component: () =>
          import ('../views/AuthManagement.vue')
  },
  {
    path: '/code',
    name: 'Code',
    component: () => import('@/views/Code.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('@/views/Contact.vue')
  },
  {
    path: '/cookies',
    name: 'Cookies',
    component: () => import('@/views/Cookies.vue')
  },
  {
    path: '/login',
    name: 'LogIn',
    component: () => import('@/views/LogIn.vue')
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import('@/views/ForgotPassword.vue')
  },
  {
    path: '/news-media',
    name: 'NewsMedia',
    component: () => import('@/views/NewsMedia.vue')
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: () => import('@/views/Privacy.vue')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('@/views/Register.vue')
  },
  {
    path: '/search-results',
    name: 'SearchResults',
    props: true,
    component: () => import('@/views/SearchResults.vue')
  },
  {
    path: '/story-create',
    name: 'StoryCreate',
    component: () => import('@/views/StoryCreate.vue')
  },
  {
    path: '/story-view',
    name: 'StoryView',
    component: () => import('@/views/StoryView.vue'),
    beforeEnter: (to, from, next) => {
        if (!store.getters.isAuth) next({ name: 'LogIn' })
        else next()
    }
  },
  {
    path: '/terms',
    name: 'Terms',
    component: () => import('@/views/Terms.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router

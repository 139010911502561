<template>
  <v-app class="full-fill">
    <v-navigation-drawer
      v-model="drawer"
      fixed
      temporary
      class="downset"
    >
      <v-list
        nav
        dense
      >
        <v-list-item-group
          v-model="group"
          active-class="deep-purple--text text--accent-4"
        >
          <router-link to="/search-results" class="text-decoration-none">
            <v-list-item>
              <v-list-item-title>Search Stories</v-list-item-title>
            </v-list-item>
          </router-link>
          <router-link to="/account" class="text-decoration-none">
            <v-list-item>
              <v-list-item-title>My Stories</v-list-item-title>
            </v-list-item>
          </router-link>
          <router-link to="/story-create" class="text-decoration-none">
            <v-list-item>
              <v-list-item-title>Create</v-list-item-title>
            </v-list-item>
          </router-link>
          <router-link to="/news-media" class="text-decoration-none">
            <v-list-item>
              <v-list-item-title>News/Media</v-list-item-title>
            </v-list-item>
          </router-link>
          <router-link to="/about" class="text-decoration-none">
            <v-list-item>
              <v-list-item-title>About</v-list-item-title>
            </v-list-item>
          </router-link>
          <router-link to="/contact" class="text-decoration-none">
            <v-list-item>
              <v-list-item-title>Contact</v-list-item-title>
            </v-list-item>
          </router-link>
          <v-divider/>
          <router-link to="/code" target="_blank" class="text-decoration-none">
            <v-list-item>
              <v-list-item-title>Code of Conduct</v-list-item-title>
            </v-list-item>
          </router-link>
          <router-link to="/terms" target="_blank" class="text-decoration-none">
            <v-list-item>
              <v-list-item-title>Terms of Service</v-list-item-title>
            </v-list-item>
          </router-link>
          <router-link to="/cookies" target="_blank" class="text-decoration-none">
            <v-list-item>
              <v-list-item-title>Cookie Policy</v-list-item-title>
            </v-list-item>
          </router-link>
          <router-link to="/privacy" target="_blank" class="text-decoration-none">
            <v-list-item>
              <v-list-item-title>Privacy Policy</v-list-item-title>
            </v-list-item>
          </router-link>
          <v-divider/>
          <router-link v-if="isAuth" to="/account" class="text-decoration-none">
            <v-list-item>
              <v-list-item-title>My Account</v-list-item-title>
            </v-list-item>
          </router-link>
          <router-link v-else to="/logIn" class="text-decoration-none">
            <v-list-item>
              <v-list-item-title>Log In</v-list-item-title>
            </v-list-item>
          </router-link>
          <router-link v-if="!isAuth" to="/register" class="text-decoration-none">
            <v-list-item>
              <v-list-item-title>Register</v-list-item-title>
            </v-list-item>
          </router-link>
          <v-list-item v-if="isAuth" @click="logOut">
            <v-list-item-title>Log Out</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <header app class="fixed full-fill">
      <v-row class="ma-0 pa-4 w-100 white">
        <v-col cols="2" align="left" class="ma-0 pa-0">
          <v-app-bar-nav-icon @click.stop="drawer = !drawer" class="d-flex d-sm-none">
          </v-app-bar-nav-icon>
        </v-col>
        <v-col cols="8" align="center" class="ma-0 pa-0">
          <router-link to="/"><v-img src="@/assets/img/logo.png" max-width="479" max-height="88" class="full-fill"></v-img></router-link>
        </v-col>
        <v-col cols="2" align="right" class="ma-0 pa-0">
          <div v-if="isAuth" class="d-none d-sm-block">
            <avatar-menu/>
          </div>
          <div v-else class="d-none d-sm-block">
            <v-btn color="blue darken-3 white--text" to="/login">Log In</v-btn>
            <v-btn color="blue darken-3 white--text" to="/register" class="ml-2">Register</v-btn>
          </div>
        </v-col>
      </v-row>
      <v-row class="darken-3 d-none d-sm-flex justify-space-between full-fill red">
        <v-col align="center" class="pa-0"><v-btn block text to="/account" class="pa-7 white--text"><strong>Stories</strong></v-btn></v-col>
        <v-col align="center" class="pa-0"><v-btn block text to="/story-create" class="pa-7 white--text"><strong>Create</strong></v-btn></v-col>
        <v-col align="center" class="pa-0"><v-btn block text to="/news-media" class="pa-7 white--text"><strong>News/Media</strong></v-btn></v-col>
        <v-col align="center" class="pa-0"><v-btn block text to="/about" class="pa-7 white--text"><strong>About</strong></v-btn></v-col>
        <v-col align="center" class="pa-0"><v-btn block text to="/contact" class="pa-7 white--text"><strong>Contact</strong></v-btn></v-col>
      </v-row>
    </header>
    <v-main class="downset w-100">
      <router-view></router-view>
    </v-main>
    <v-footer app class="blue d-none d-sm-flex darken-3 full-fill justify-center" style="max-width: 100vw;">
      <cookie-law theme="blood-orange" buttonClass="cookie-button">
        <div slot="message">
          This website uses cookies to ensure you get the best experience. <router-link to="/cookie-policy" target="_blank" class="link pointer white underline">Learn More</router-link>
        </div>
      </cookie-law>
      <v-btn text to="/code" target="_blank" class="white--text" >Code of Conduct</v-btn>
      <v-btn text to="/terms" target="_blank" class="white--text" >Terms of Service</v-btn>
      <v-btn text to="/cookies" target="_blank" class="ml-2 mr-2 white--text">Cookie Policy</v-btn>
      <v-btn text to="/privacy" target="_blank" class="white--text">Privacy Policy</v-btn>
      <v-btn text disabled><span class="white--text">© Honor Tag 2022</span></v-btn>
    </v-footer>
  </v-app>
</template>

<script>
  import { mapGetters } from 'vuex'
  import CookieLaw from 'vue-cookie-law'
  import AvatarMenu from '@/components/AvatarMenu.vue'

  export default {
    name: 'App',

    components: {
      AvatarMenu,
      CookieLaw
    },

    data () {
      return {
        drawer: null,
        group: null
      }
    },

    computed: {
      ...mapGetters([
        'isAuth'
      ])
    },

    methods: {
      logOut () {
        this.$store.dispatch('logOut')
      }
    }
  }
</script>

<style scoped>
  @font-face { font-family: Lexend_Deca; src: url(assets/fonts/LexendDeca-Regular.ttf); }
  @font-face { font-family: Vollkorn; src: url(assets/fonts/VollkornSC-Regular.ttf); }
  @font-face { font-family: Vollkorn-black; src: url(assets/fonts/VollkornSC-Black.ttf); }
  @font-face { font-family: Vollkorn-bold; src: url(assets/fonts/VollkornSC-Bold.ttf); }
  @font-face { font-family: Vollkorn-semibold; src: url(assets/fonts/VollkornSC-SemiBold.ttf); }

  .downset {
    margin: 0;
    margin-top: 176px;
    padding: 0;
    padding-bottom: 176px;
  }

  .fixed {
    position: fixed;
    z-index: 9999;
  }

  .full-fill {
    margin: 0 !important;
    padding: 0 !important;
    width: 100% !important;
  }

  .v-main {
    background-image: url("assets/img/White_Background_Tile.png");
    background-repeat: repeat;
    font-family: Lexend_Deca;
  }

  @media only screen and (max-width: 600px) {
    .downset {
      margin: 0;
      margin-top: 56px;
      padding: 0;
      padding-bottom: 56px;
    }
  }
</style>
